import {
  Badge,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Info, PlayCircleFilled } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import API from "../../../../api/api";
import ApiUri from "../../../../api/api-uri";
import ConfirmationDialogActivator from "../../../../components/confirmation-dialog/confirmation-dialog-activator";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import { SnackbarContext } from "../../../../context/snackbar";
import { Song } from "../../../../models/song/song";
import AudioPlayer from "react-h5-audio-player";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface IMmdzSyncDialog {
  song: Song;
  isOpen: boolean;
  onDialogClose: Function;
  songIndex: number;
}

export default function MmdzSyncDialog({
  song,
  isOpen,
  onDialogClose,
  songIndex,
}: IMmdzSyncDialog) {
  const [mmdzData, setMmdzData] = useState<any>();
  const [isrc, setIsrc] = useState(song.isrc);
  const [isrcConfirmed, setIsrcConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { initSnackbarGeneric, initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);

  const fetchData = () => {
    API.get(ApiUri.MMDZ + ApiUri.SONG + "/" + song.id).then(
      (response: any) => {
        if (Object.keys(response.data).length === 0) {
          initSnackbarGeneric("No data found on MMDZ for this song, sorry");
          setIsLoading(false);
        } else {
          setMmdzData(response.data);
          setIsLoading(false);
          setIsrcConfirmed(true);
        }
      },
      (error) => {
        initSnackbarError(error);
        setIsLoading(false);
      }
    );
  };

  const handleFieldChange = (event: any) => {
    setIsrc(event.target.value);
  };

  const handleClickNext = () => {
    const formData = {
      isrc: isrc,
    };

    setIsLoading(true);
    API.patch(ApiUri.SONG + "/" + song.id, formData).then(
      (response) => {
        // setTimeout(() => {
        fetchData();
        // }, 3000);
      },
      (err) => {
        initSnackbarError(err);
        setIsLoading(false);
      }
    );
  };

  const mapMmdzData = (artwork, fullSongVersion, genreTags) => {
    if (!mmdzData || Object.keys(mmdzData).length === 0) {
      return null;
    } else {
      return {
        id: song.id,
        barcode: mmdzData?.barcode,
        release_name: mmdzData?.product_title,
        isrc: isrc,
        release_date: mmdzData?.release_start_date,
        artists: mmdzData?.artists,
        genres: genreTags,
        artwork: artwork,
        full_song_version: fullSongVersion,
      };
    }
  };

  const handleClose = (
    artwork = null,
    fullSongVersion = null,
    genreTags = null
  ) => {
    // setMmdzData(null);
    // setArtwork(null);
    // setGenreTags(null);
    // setFullSongVersion(null);
    // setIsrc(song?.isrc);

    // resetting state
    setIsrcConfirmed(false);
    setIsLoading(false);
    setMmdzData(null);
    setIsrc("");
    const mmdzDataToBeSent = mapMmdzData(artwork, fullSongVersion, genreTags);
    onDialogClose(mmdzDataToBeSent, songIndex);
    return;
  };

  const handleConfirm = async () => {
    const data = {
      id: song.id,
      barcode: mmdzData?.barcode,
      release_name: mmdzData?.productTitle,
      isrc: isrc,
      release_date: mmdzData?.releaseStartDate,
      artists: mmdzData?.artists,
      genres: null,
      artwork: null,
      full_song_version: null,
    };

    if (!mmdzData || !data) {
      handleClose();
    }

    let artwork = null;
    let fullSongVersion = null;
    let genreTags = null;

    const apiPromises: Promise<boolean>[] = [];

    if (mmdzData.artworkUrl) {
      setIsLoading(true);
      apiPromises.push(
        API.post(ApiUri.SONG + "/" + data.id + ApiUri.ARTWORK_FROM_URL, {
          url: mmdzData.artworkUrl,
        })
          .then(
            (response) => {
              artwork = response.data;
              initSnackbarSuccess("Artwork successfully synced!");
              return true;
            },
            (err) => {
              initSnackbarError("Artwork sync failed, please try again later");
              return false;
            }
          )
          .finally(() => setIsLoading(false))
      );
    }

    if (mmdzData.fullSongVersionUrl) {
      setIsLoading(true);
      apiPromises.push(
        API.post(
          ApiUri.SONG +
            "/" +
            data.id +
            ApiUri.VERSION_FROM_URL +
            "?version=full",
          {
            url: mmdzData.fullSongVersionUrl,
          }
        )
          .then(
            (response) => {
              fullSongVersion = response.data;
              initSnackbarSuccess("Song file successfully synced!");
              return true;
            },
            (err) => {
              initSnackbarError(
                "Song file sync failed, please try again later"
              );
              return false;
            }
          )
          .finally(() => setIsLoading(false))
      );
    }

    if (mmdzData.genreTags) {
      setIsLoading(true);
      apiPromises.push(
        API.post(
          ApiUri.SONG +
            "/" +
            data.id +
            ApiUri.TAGS +
            ApiUri.SYNC +
            "?type=genre",
          {
            tags: mmdzData.genreTags,
          }
        )
          .then(
            (response) => {
              genreTags = response.data.genres;
              initSnackbarSuccess("Genre tags successfully synced!");
              return true;
            },
            (err) => {
              initSnackbarError(
                "Genre tags sync failed, please try again later"
              );
              return false;
            }
          )
          .finally(() => setIsLoading(false))
      );
    }

    // Wait for all promises to resolve
    const results = await Promise.all(apiPromises);

    // Now, after all API calls have completed, update the state and close the dialog
    if (results.every((result) => result)) {
      handleClose(artwork, fullSongVersion, genreTags);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      onClose={() => handleClose()}
      fullWidth
    >
      <DialogTitle>
        <Typography>
          <b>Link song from MMDZ</b>
        </Typography>
      </DialogTitle>
      <Divider />
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: 20 }}
          >
            <Grid item>
              <CircularProgress size={60} />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <React.Fragment>
          <Stack spacing={2}>
            <DialogContent>
              {!isrcConfirmed ? (
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <Tooltip
                      title="ISRC is required to be able to sync song metadata"
                      placement="top-end"
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        badgeContent={<Info color="disabled" />}
                      />
                    </Tooltip>
                    <TextField
                      required
                      onChange={handleFieldChange}
                      name="isrc"
                      type="text"
                      label="ISRC"
                      value={isrc || ""}
                    />
                  </FormControl>
                  <ConfirmationDialogActivator
                    onClick={handleClickNext}
                    disabled={!isrc}
                    actionName="Next"
                    content="Are you sure ISRC code is correct?"
                  />
                </Stack>
              ) : (
                <Grid container spacing={2}>
                  {mmdzData?.barcode && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          name="barcode"
                          label="Barcode"
                          value={mmdzData?.barcode || ""}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {mmdzData?.product_title && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          name="release_name"
                          label="Release Name"
                          value={mmdzData?.product_title || ""}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {mmdzData?.artists && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          name="recording_artist"
                          label="Artist Name"
                          value={
                            mmdzData?.artists
                              ?.map((artist) => artist.name)
                              .join(", ") || ""
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {mmdzData.release_start_date && (
                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          label="Release Date"
                          disabled
                          fullWidth
                          value={
                            mmdzData?.release_start_date
                              ? new Date(mmdzData.release_start_date)
                              : null
                          }
                          onChange={() => null}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  )}

                  {mmdzData?.artworkUrl && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Artwork</Typography>
                      <img src={mmdzData.artworkUrl} alt="" width="100%" />
                    </Grid>
                  )}

                  {mmdzData?.fullSongVersionUrl && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Audio File</Typography>
                      <AudioPlayer
                        style={{ marginBottom: 10 }}
                        autoPlayAfterSrcChange={false}
                        showSkipControls={false}
                        showJumpControls={false}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        layout="horizontal-reverse"
                        src={mmdzData.fullSongVersionUrl}
                        customIcons={{
                          play: (
                            <PlayCircleFilled
                              color="primary"
                              style={{ fontSize: "1em" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                  )}

                  {mmdzData?.genreTags && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Genre Tags</Typography>
                      {mmdzData.genreTags.map((tag, index) =>
                        tag ? <Chip key={index} label={tag} /> : null
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </DialogContent>
            <Divider />
            <DialogActions style={{ marginBottom: "10px" }}>
              <Grid container justifyContent="space-evenly">
                <ActionButtonSmall onClick={() => handleClose()}>
                  Cancel
                </ActionButtonSmall>
                <ActionButtonSmall
                  disabled={!mmdzData || isLoading}
                  color="primary"
                  onClick={handleConfirm}
                >
                  Confirm
                </ActionButtonSmall>
              </Grid>
            </DialogActions>
          </Stack>
        </React.Fragment>
      )}
    </Dialog>
  );
}

