import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import { Composition } from "../../../../models/composition/composition";
import { SnackbarContext } from "../../../../context/snackbar";
import { useParams } from "react-router";
import CompositionSongs from "./composition-songs";
import { Song } from "../../../../models/song/song";
import SongWritersPercentageShareContextProvider from "../../../../context/songwriters-percentage-share";
import {
  Divider,
  Grid,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Compositions from "../../../../enums/compositions";
import ConfirmationDialogActivator from "../../../../components/confirmation-dialog/confirmation-dialog-activator";
import { useHistory } from "react-router-dom";
import CompositionForm from "../../common-components/CompositionForm";
import { LyricsRounded, VolumeUpRounded } from "@mui/icons-material";
import { calculateShares } from "../../common-components/CompositionForm/form-step-two";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import ContractModal from "../../../../components/ContractModal";
import { AuthContext } from "../../../../context/auth";
import { profileTypes } from "../../../settings/contracts/types";
import ProfileTypes from "../../../../enums/profile-types";
import { error } from "console";

export default function EditSong() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [composition, setComposition] = useState({} as Composition);
  const [song, setSong] = useState({} as Song);
  const history = useHistory();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const [activeStep, setActiveStep] = useState(1);
  const [contractContent, setContractContent] = useState("");
  const [contractModalOpen, setContractModalOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (composition && composition.id) {
      return;
    }

    startGlobalLoader();

    API.get(ApiUri.SONG + "/" + id).then(
      (response) => {
        if (isMounted) {
          // @todo Uncomment later on, allow editing for test
          // if (response.data.composition.status !== Compositions.STATUS_DRAFT) {
          //   stopGlobalLoader();
          //   initSnackbarGeneric("This song is not in draft status so you can't edit it");
          //   history.push("/songs/manage");
          //   return;
          // }
          setSong(response.data);
          API.get(ApiUri.COMPOSITION + "/" + response.data.composition.id).then(
            (response) => {
              if (response.data.song_writers) {
                const newTotalShares = calculateShares(
                  response.data.song_writers
                );
                if (newTotalShares !== 100) {
                  setActiveStep(0);
                  initSnackbarGeneric(
                    'The total shares percentage should sum up to 100 before proceeding to "Master Recording"'
                  );
                }
              }
              setComposition(response.data);
              stopGlobalLoader();
            }
          );
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );

    return () => {
      isMounted = false;
    };
  }, [composition]); // eslint-disable-line

  const songIsValid = (song) => {
    const requiredTagsNotInstrumental = [
      song.genres,
      song.instruments,
      song.lyrical_themes,
      song.moods,
      song.tempos,
      song.vocals,
      song.types,
    ];
    const requiredTagsInstrumental = [
      song.genres,
      song.instruments,
      song.moods,
      song.tempos,
      song.types,
    ];

    return (
      song.release_name?.trim() &&
      (song.artists ? song.artists[0]?.name?.trim() : false) &&
      song.performers?.length !== 0 &&
      song.is_ppl_registered !== null &&
      (!song.is_ppl_registered || song?.ppl_id?.length === 9) &&
      song.full_song_version &&
      song.artwork &&
      (composition?.is_instrumental
        ? requiredTagsInstrumental.every((tag) => tag.length > 0)
        : requiredTagsNotInstrumental.every((tag) => tag.length > 0))
    );
  };

  const handleSubmit = (signatureId = "") => {
    startGlobalLoader();
    const atLeastOneSong = composition.songs.length > 0;
    if (atLeastOneSong) {
      if (composition.songs.every((song) => songIsValid(song))) {
        if (signatureId) {
          API.put(
            ApiUri.COMPOSITION +
              "/" +
              composition.id +
              ApiUri.SIGNATURE +
              "/" +
              signatureId
          ).then(
            (response) => {
              API.post(
                ApiUri.COMPOSITION + "/" + composition.id + ApiUri.SUBMIT
              ).then(
                (response) => {
                  initSnackbarSuccess(
                    "Song successfully submitted! We will let you know after we process it."
                  );
                  history.push("/songs/manage");
                  stopGlobalLoader();
                },
                (error) => {
                  initSnackbarError(error);
                  stopGlobalLoader();
                }
              );
            },
            (error) => {
              initSnackbarError(error);
              stopGlobalLoader();
            }
          );
        } else {
          API.post(
            ApiUri.COMPOSITION + "/" + composition.id + ApiUri.SUBMIT
          ).then(
            (response) => {
              initSnackbarSuccess(
                "Song successfully submitted! We will let you know after we process it."
              );
              history.push("/songs/manage");
              stopGlobalLoader();
            },
            (error) => {
              initSnackbarError(error);
              stopGlobalLoader();
            }
          );
        }
      } else {
        initSnackbarError("At least one Master Recording is not complete");
        stopGlobalLoader();
      }
    } else {
      initSnackbarError("You have to register at least one Master Recording");
      stopGlobalLoader();
    }
  };

  const showDownloadCSVButton =
    song.id &&
    ((user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE &&
      (song?.composition.status === Compositions.STATUS_TAKEN_DOWN ||
        song?.composition.status === Compositions.STATUS_SUBMITTED ||
        song?.composition.status === Compositions.STATUS_PROCESSING ||
        song?.composition.status === Compositions.STATUS_PUBLISHED)) ||
      song?.composition.status === Compositions.STATUS_PROCESSING ||
      song?.composition.status === Compositions.STATUS_PUBLISHED);

  const checkContractNeed = () => {
    if (
      user?.last_accessed_profile?.profile_type.name === ProfileTypes.SERVICE ||
      user?.last_accessed_profile?.profile_type.name ===
        ProfileTypes.PUBLISHER_ADMINISTRATOR
    ) {
      handleSubmit();
    } else {
      startGlobalLoader();
      API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
        (response) => {
          const childProfile = response.data;

          const parentProfileId = childProfile.parent.id;

          API.get(ApiUri.PROFILE + "/" + parentProfileId).then(
            (response) => {
              const parentProfile = response.data;
              const parentProfileType = parentProfile.profile_type.name;

              const referenceProfile =
                parentProfileType === ProfileTypes.PUBLISHER
                  ? parentProfile
                  : childProfile;

              const firstActiveProduct =
                referenceProfile.package_song_writers.find(
                  (product) => product.active
                );
              const productType =
                referenceProfile.parent.profile_information.name ===
                "Anara Publishing"
                  ? "Subscription"
                  : firstActiveProduct.package.type;

              const requestBody = {
                product: productType,
                profile_type: {
                  name: referenceProfile.profile_type.name,
                  id:
                    referenceProfile.profile_type.name === "Songwriter"
                      ? 4
                      : referenceProfile.profile_type.name === "Publisher"
                      ? 3
                      : 0,
                },
              };

              const profileIdToBeUsedForGettingContracts =
                parentProfileType === ProfileTypes.PUBLISHER
                  ? parentProfile.parent.id
                  : parentProfileId;

              API.post(
                ApiUri.PROFILE +
                  "/" +
                  profileIdToBeUsedForGettingContracts +
                  "/get-contract",
                requestBody
              ).then(
                (response) => {
                  // checks if it should display the contract or skip it
                  if (response.data.display_publishing_agreement) {
                    stopGlobalLoader();
                    setContractContent(
                      response.data.publishing_agreement_content
                    );
                    setContractModalOpen(true);
                  } else {
                    handleSubmit();
                  }
                },
                (err) => {
                  initSnackbarError(err);
                  stopGlobalLoader();
                }
              );
            },
            (err) => {
              initSnackbarError(err);
              stopGlobalLoader();
            }
          );
        },
        (err) => {
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    }
  };

  const handleCloseContractModal = (approved = false, signatureId = "") => {
    setContractModalOpen(false);
    if (approved) {
      handleSubmit(signatureId);
    } else {
      initSnackbarError(
        "You have to agree with the Publishing Terms and Conditions in order to submit a song."
      );
    }
  };

  const testSite =
    window.location.href.includes("test") ||
    window.location.href.includes("localhost");

const showButtons =
    song?.composition?.status === Compositions.STATUS_DRAFT ||
    ((song?.composition?.status === Compositions.STATUS_PUBLISHED ||
      song?.composition?.status === Compositions.STATUS_SUBMITTED ||
      song?.composition?.status === Compositions.STATUS_PROCESSING) &&
      user?.is_mmpz_service_admin &&
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE);

  return song.id && composition.id ? (
    <React.Fragment>
      <Stack direction={"row"} justifyContent={"center"}>
        <Stepper sx={{ width: "50%" }} activeStep={activeStep} alternativeLabel>
          <Step sx={{ cursor: "pointer" }} onClick={() => setActiveStep(0)}>
            <StepLabel
              sx={{ color: activeStep === 0 ? "#000000" : "#a0a0a0" }}
              StepIconComponent={LyricsRounded}
            >
              <Typography
                fontWeight={activeStep === 0 ? "bold" : 400}
                color={activeStep === 0 ? "#000000" : "#a0a0a0"}
              >
                Composition
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              sx={{
                color: activeStep === 1 ? "#000000" : "#a0a0a0",
              }}
              StepIconComponent={VolumeUpRounded}
            >
              <Typography
                fontWeight={activeStep === 1 ? "bold" : 400}
                color={activeStep === 1 ? "#000000" : "#a0a0a0"}
              >
                Master Recording
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Stack>

      {activeStep === 0 && (
        <SongWritersPercentageShareContextProvider composition={composition}>
          <CompositionForm
            mode="edit"
            composition={composition}
            setComposition={setComposition}
            setActiveGlobalStep={setActiveStep}
          />
        </SongWritersPercentageShareContextProvider>
      )}
      {activeStep === 1 && (
        <React.Fragment>
          {composition.songs ? (
            <CompositionSongs
              selectedSong={song}
              composition={composition}
              setComposition={setComposition}
            />
          ) : null}

          <Grid container spacing={4} mb={4}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {showButtons ? (
              <React.Fragment>
                <Grid item xs={6}>
                  <ActionButtonSmall onClick={() => setActiveStep(0)}>
                    Back
                  </ActionButtonSmall>
                </Grid>

                <Grid item xs={6}>
                  <ConfirmationDialogActivator
                    onClick={checkContractNeed}
                    actionName="Submit All"
                    content="Are you sure all the required data is correct?"
                  />
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>
        </React.Fragment>
      )}
      {contractModalOpen && (
        <ContractModal
          modalOpened={contractModalOpen}
          handleClose={handleCloseContractModal}
          contractContent={contractContent}
          requireSignature={true}
        />
      )}
      {showDownloadCSVButton && (
        <Stack marginBottom={2}>
          <ActionButtonSmall
            href={
              `https://api.mmpz.${
                testSite ? "test." : ""
              }myclientzone.com/api/v1` +
              ApiUri.SONG +
              "/" +
              song.id +
              ApiUri.DOWNLOAD
            }
          >
            Download CSV
          </ActionButtonSmall>
        </Stack>
      )}
    </React.Fragment>
  ) : null;
}

