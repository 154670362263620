import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Grid,
  Paper,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Song } from "../../../../../models/song/song";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import FormStepOne from "./form-step-one";
import FormStepTwo from "./form-step-two";
import FormStepThree from "./form-step-three";
import FormStepFour from "./form-step-four";
import { Composition } from "../../../../../models/composition/composition";
import { Sync, Info } from "@mui/icons-material";
import FormStepFive from "./form-step-five";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";

interface INewSongForm {
  songData: Song;
  composition: Composition;
  setComposition: Function;
  songIndex: number;
  openMmdzSyncDialog: Function;
  onMmdzSyncDialogClose: Function;
  formData: any;
  onFormChange: any;
  mmdzData: any;
}

export default function EditSongForm({
  songData,
  composition,
  setComposition,
  songIndex,
  openMmdzSyncDialog,
  onMmdzSyncDialogClose,
  formData,
  onFormChange,
  mmdzData,
}: INewSongForm) {
  console.log("🚀 ~ songData:", songData);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const [song, setSong] = useState({ ...songData, ...formData });
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Song Metadata", "Songs", "Artwork", "Tags", "DSP"];

  useEffect(() => {
    onFormChange(song);
  }, [song]);

  useEffect(() => {
    if (mmdzData && Object.keys(mmdzData).length > 0) {
      setSong((oldSong) => ({ ...oldSong, ...mmdzData }));
    }
  }, [mmdzData]);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <FormStepOne song={song} setSong={setSong} />;
      case 1:
        return <FormStepTwo song={song} setSong={setSong} />;
      case 2:
        return <FormStepThree song={song} setSong={setSong} />;
      case 3:
        return (
          <FormStepFour
            song={song}
            setSong={setSong}
            isInstrumental={composition.is_instrumental}
          />
        );
      case 4:
        return <FormStepFive song={song} setSong={setSong} />;
    }
  }

  const isStepOptional = (step: number) => {
    return step === 4;
  };

  const isStepClickable = (step: number) => {
    return activeStep === -1 || activeStep > step;
  };

  const isPplIdMissing = () => {
    return (
      activeStep === 0 && song.is_ppl_registered && song.ppl_id?.length !== 9
    );
  };

  const isStepValid = () => {
    if (activeStep === 0) {
      if (
        !song.release_name?.trim() ||
        (song.artists ? !song.artists[0]?.name.trim() : true) ||
        song.performers?.length === 0 ||
        song.is_ppl_registered === null ||
        (song.is_ppl_registered && song.ppl_id?.length !== 9)
      ) {
        return false;
      }
    }
    if (activeStep === 1) {
      if (!song.full_song_version) {
        return false;
      }
    }

    if (activeStep === 2) {
      if (!song.artwork) {
        return false;
      }
    }

    if (activeStep === 3) {
      const requiredTagsNotInstrumental = [
        song.genres,
        song.instruments,
        song.lyrical_themes,
        song.moods,
        song.tempos,
        song.vocals,
        song.types,
      ];
      const requiredTagsInstrumental = [
        song.genres,
        song.instruments,
        song.moods,
        song.tempos,
        song.types,
      ];
      if (
        !composition.is_instrumental &&
        requiredTagsNotInstrumental.some((tag) => tag.length === 0)
      ) {
        return false;
      } else if (
        composition.is_instrumental &&
        requiredTagsInstrumental.some((tag) => tag.length === 0)
      ) {
        return false;
      }
    }

    return true;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSaveClick = () => {
    startGlobalLoader();

    API.put(ApiUri.SONG + "/" + song.id, song).then(
      (response) => {
        setSong(response.data);

        const compositionSongs = composition.songs.map((compositionSong) => {
          if (compositionSong.id === song.id) {
            compositionSong = song;
          }
          return compositionSong;
        });

        setComposition((previousState) => ({
          ...previousState,
          songs: compositionSongs,
        }));
        stopGlobalLoader();
        initSnackbarSuccess("Song data successfully updated");
        setActiveStep((prevActiveStep) => {
          if (prevActiveStep === steps.length - 1) {
            return -1;
          }
          return prevActiveStep + 1;
        });
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  return (
    <React.Fragment>
      <Paper elevation={4} sx={{ padding: 2 }}>
        {activeStep === 0 ? (
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <ActionButtonSmall
                onClick={() => openMmdzSyncDialog(song, songIndex)}
              >
                Link song from MMDZ
                <Sync />
              </ActionButtonSmall>
              <Tooltip
                title="Your song metadata will be synced from MMDZ"
                placement="top-end"
                style={{ marginBottom: 10 }}
              >
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={<Info color="disabled" />}
                />
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
              style?: any;
              onClick?: any;
            } = {};

            // continuar trabalhando nessa logica, diferenciar next de save (labels), fazer com que cada master seja clicavel, arrumar espacamento, etc

            const handleNextClick = () => {
              if (isPplIdMissing()) {
                initSnackbarGeneric(
                  "Please make sure the PPL ID is 9 digits long"
                );
                return;
              }
              if (!isStepValid()) {
                initSnackbarGeneric("Please fill in all required fields");
                return;
              }
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            };

            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepClickable(index)) {
              labelProps.style = { cursor: "pointer" };
              labelProps.onClick = () => setActiveStep(index);
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
                <StepContent>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      {getStepContent(index)}
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" spacing={2}>
                        <ActionButtonSmall
                          disabled={index === 0}
                          onClick={handleBack}
                        >
                          Back
                        </ActionButtonSmall>
                        {index === steps.length - 1 ? (
                          <ActionButtonSmall onClick={handleSaveClick}>
                            Save Master Recording
                          </ActionButtonSmall>
                        ) : (
                          <ActionButtonSmall onClick={handleNextClick}>
                            Next
                          </ActionButtonSmall>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
    </React.Fragment>
  );
}

