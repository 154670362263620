import React, { useState, createContext, useContext } from "react";
import HomeIcon from "@mui/icons-material/Home";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import WidgetsIcon from "@mui/icons-material/Widgets";
import {
  AccountBalance,
  IosShare,
  ManageAccounts,
  Paid,
  QuestionMark,
  ShoppingBag,
} from "@mui/icons-material";
import { AuthContext } from "../context/auth";
import ProfileTypes from "../enums/profile-types";
import { useLocation } from "react-router-dom";

const SERVICE_NAVIGATION = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon />,
    open: false,
    children: [],
  },
  {
    path: "/songs",
    name: "Songs",
    icon: <LibraryMusicIcon />,
    open: false,
    children: [
      {
        path: "/songs/manage",
        name: "Manage Songs",
        active: false,
      },
    ],
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    icon: <ManageAccounts />,
    open: false,
    children: [],
  },
  {
    path: "/sub-profiles",
    name: "Sub Profiles",
    icon: <WidgetsIcon />,
    open: false,
    children: [
      {
        path: "/sub-profiles/new",
        name: "New Sub Profile",
        active: false,
      },
      {
        path: "/sub-profiles/manage",
        name: "Manage Sub Profiles",
        active: false,
      },
    ],
  },
  {
    path: "/royalties",
    name: "Royalties",
    icon: <Paid />,
    open: false,
    children: [
      {
        path: "/royalties/import-royalty-statement",
        name: "Import Royalty Statement",
        active: false,
      },
      {
        path: "/royalties/default-royalty-values",
        name: "Default Royalty Values",
        active: false,
      },
    ],
  },
  {
    path: "/help",
    name: "Help",
    icon: <QuestionMark />,
    open: false,
    children: [
      {
        path: "/help/view",
        name: "View Help",
        active: false,
      },
      {
        path: "/help/edit",
        name: "Edit Help",
        active: false,
      },
    ],
  },
];

const PUBLISHER_ADMINISTRATOR_NAVIGATION = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon />,
    open: false,
    children: [],
  },
  {
    path: "/songs",
    name: "Songs",
    icon: <LibraryMusicIcon />,
    open: false,
    children: [
      {
        path: "/songs/manage",
        name: "Manage Songs",
        active: false,
      },
    ],
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    icon: <ManageAccounts />,
    open: false,
    children: [],
  },
  {
    path: "/sub-profiles",
    name: "Sub Profiles",
    icon: <WidgetsIcon />,
    open: false,
    children: [
      {
        path: "/sub-profiles/new",
        name: "New Sub Profile",
        active: false,
      },
      {
        path: "/sub-profiles/manage",
        name: "Manage Sub Profiles",
        active: false,
      },
    ],
  },
  {
    path: "/products",
    name: "Products",
    icon: <ShoppingBag />,
    open: false,
    children: [
      {
        path: "/products/packages",
        name: "Packages",
        active: false,
      },
      {
        path: "/products/subscriptions",
        name: "Subscriptions",
        active: false,
      },
    ],
  },
  {
    path: "/exporters",
    name: "Exporters",
    icon: <IosShare />,
    open: false,
    children: [
      {
        path: "/exporters/feeds-generator",
        name: "Feeds Generator",
        active: false,
      },
    ],
  },
  {
    path: "/royalties",
    name: "Royalties",
    icon: <Paid />,
    open: false,
    children: [
      {
        path: "/royalties/import-royalty-statement",
        name: "Import Royalty Statement",
        active: false,
      },
      {
        path: "/royalties/default-royalty-values",
        name: "Default Royalty Values",
        active: false,
      },
    ],
  },
  {
    path: "/help",
    name: "Help",
    icon: <QuestionMark />,
    open: false,
    children: [
      {
        path: "/help/view",
        name: "View Help",
        active: false,
      },
      {
        path: "/help/edit",
        name: "Edit Help",
        active: false,
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <SettingsIcon />,
    open: false,
    children: [
      {
        path: "/settings/platforms",
        name: "Platforms",
        active: false,
      },
      {
        path: "/settings/contracts",
        name: "Contracts",
        active: false,
      },
    ],
  },
];

const PUBLISHER_NAVIGATION = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon />,
    open: false,
    children: [],
  },
  {
    path: "/songs",
    name: "Songs",
    icon: <LibraryMusicIcon />,
    open: false,
    children: [
      {
        path: "/songs/manage",
        name: "Manage Songs",
        active: false,
      },
    ],
  },
  {
    path: "/payments/manage-subscriptions",
    name: "Subscriptions",
    icon: <Paid />,
    open: false,
    children: [],
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    icon: <ManageAccounts />,
    open: false,
    children: [],
  },
  {
    path: "/sub-profiles",
    name: "Sub Profiles",
    icon: <WidgetsIcon />,
    open: false,
    children: [
      {
        path: "/sub-profiles/new",
        name: "New Sub Profile",
        active: false,
      },
      {
        path: "/sub-profiles/manage",
        name: "Manage Sub Profiles",
        active: false,
      },
    ],
  },
  {
    path: "/help",
    name: "Help",
    icon: <QuestionMark />,
    open: false,
    children: [
      {
        path: "/help/view",
        name: "View Help",
        active: false,
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <SettingsIcon />,
    open: false,
    children: [
      {
        path: "/settings/platforms",
        name: "Platforms",
        active: false,
      },
    ],
  },
];

const SONGWRITER_NAVIGATION = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon />,
    open: false,
    children: [],
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    icon: <ManageAccounts />,
    open: false,
    children: [],
  },
  {
    path: "/songs",
    name: "Songs",
    icon: <LibraryMusicIcon />,
    open: false,
    children: [
      {
        path: "/songs/manage",
        name: "Manage Songs",
        active: false,
      },
      {
        path: "/songs/new",
        name: "Add New Song",
        active: false,
      },
    ],
  },
  {
    path: "/payments",
    name: "Payments",
    icon: <Paid />,
    open: false,
    children: [
      {
        path: "/payments/manage-subscriptions",
        name: "Manage Subscriptions",
        active: false,
      },
    ],
  },
  {
    path: "/help",
    name: "Help",
    icon: <QuestionMark />,
    open: false,
    children: [
      {
        path: "/help/view",
        name: "View Help",
        active: false,
      },
    ],
  },
];

export interface INavigationContext {
  navigation: any;
  setNavigation: any;
}

export const NavigationContext = createContext({} as INavigationContext);

const NavigationContextProvider = ({ children }: any) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  let navigationType: any = null;

  if (user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE) {
    navigationType = SERVICE_NAVIGATION;
  }

  if (
    user?.last_accessed_profile.profile_type.name ===
    ProfileTypes.PUBLISHER_ADMINISTRATOR
  ) {
    navigationType = PUBLISHER_ADMINISTRATOR_NAVIGATION;
  }

  if (
    user?.last_accessed_profile.profile_type.name === ProfileTypes.PUBLISHER
  ) {
    navigationType = PUBLISHER_NAVIGATION;
  }

  if (
    user?.last_accessed_profile.profile_type.name === ProfileTypes.SONGWRITER
  ) {
    navigationType = SONGWRITER_NAVIGATION;
  }

  const appNavigation = navigationType?.map((navigationPage: any) => {
    if (navigationPage.path === location.pathname) {
      navigationPage.open = true;
    }

    const children = navigationPage.children.map((child: any) => {
      if (child.path === location.pathname) {
        navigationPage.open = true;
        child.active = true;
      }
      return child;
    });

    navigationPage.children = children;
    return navigationPage;
  });

  const [navigation, setNavigation] = useState(appNavigation);

  return (
    <NavigationContext.Provider
      value={{
        navigation,
        setNavigation,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
